import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import tw from 'tailwind.macro';
import Button from '../../components/button';
import CollapsibleBox from '../../components/collapsibleBox';
import Grid from '../../components/grid';
import Layout from '../../components/layout';
import LeadText from '../../components/leadText';
import Masthead from '../../components/masthead';
import Section from '../../components/section';
import SEO from '../../components/seo';
import SafeLink from '../../components/safeLink';

const ProjectsSCOPRA = ({ data, location }) => {
  const parentTitle = 'Projects';
  const pageTitle =
    'Social Justice and COVID-19 Policy and Relief Monitoring Alliance (SCOPRA)';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:40vh;`]}
      />
      <Section>
        <h2>Purpose</h2>
        <div css={tw`mb-10 ml-10 float-right`}>
          <Img fixed={data.SULogoDark.childImageSharp.fixed} />
        </div>
        <p>
          The purpose of the project is to constitute a social accountability
          bulwark that regularly tracks all Coronavirus COVID-19 policies and
          relief measures to assess and ensure compliance with the equality duty
          and related social justice commitments, other human rights obligations
          and democratic governance dictates with a view to fostering social
          justice, the rule of law, sustainable democracy and peace in line with
          UN Sustainable development Goals (SDGs) particularly, equality (10),
          the rule of law (16) and international cooperation (17).
        </p>
        <p>
          <strong>The project has three (3) components:</strong>
        </p>
        <ol>
          <li>
            Monitoring policy responses including applying the SIAM and SIAS to
            test for constitutional compliance and social justice;
          </li>
          <li>Monitoring implementation; and</li>
          <li>Distribution of relief.</li>
        </ol>
        <hr css={tw`my-10`} />
        <strong>Updates:</strong> Read more about the Chair's work on COVID-19.{' '}
        <SafeLink
          to="/blog/topic/covid-19/"
          css={tw`text-maroon hover:underline`}
        >
          Read the latest articles &rarr;
        </SafeLink>
      </Section>
      <Section isShaded>
        <CollapsibleBox collapsibleByDefault>
          <h2>Background</h2>
          <div>
            <LeadText>
              The project is an outcome of two digital Social Justice
              Roundtables, in which local and international social justice
              experts and others assessed the social impacts of the Coronavirus
              COVID-19 policy responses to it, focussing on the impact of
              poverty and inequality, particularly taking into account the
              systemic legacy of poverty and inequality in South Africa. The
              roundtables were backed by seven (7) thematic working groups.
              These groups focussed on the impact of COVID-19 and Policy
              responses on: Poverty Mapping, Food Security and other Social
              Rights, Education, Rule of Law and Policy Tracking, Gender,
              Economy and Economic Rights and Health and Mental Health.
            </LeadText>
            <p>
              The Social Justice Roundtables and seven (7) thematic working
              groups were convened by the Chair in Social Justice research at
              Stellenbosch University in response to social justice concerns,
              following the declaration of a state of disaster by the South
              African Government on 15 March restricting movement, social
              intercourse and commerce, in terms of the Disaster Management Act
              of 2002. The restrictions, which essentially seek to contain the
              transmission of COVID-19, prevent a deluge of demand for limited
              health services, including ventilators and also combat predatory
              commercial practices such as exponential price inflation,
              subsequently resulted in a national lockdown.
            </p>
            <p>
              The Roundtable concluded that some of the Regulations, Guidelines,
              Directions, Statements and By-Laws issued under the Disaster
              Management Act were unduly disruptive on the socio-economic lives
              of certain sections in society identified in terms of the
              prohibited ground of discrimination in the Constitution, the
              African Charter on Human Rights and the Universal Declaration on
              Human Rights, read with its supporting conventions and related
              instruments. The conclusion was that the undue hardship inflicted
              on certain groups such as women, rural communities, township
              residents and economies, those with mental health and
              disadvantaged learners and students, among others, could have been
              prevented if government had used disaggregated data and data
              analytics and involved all end user groupings in the policy design
              process. The dialogues further concluded that government needs to
              give due regard to the equality duty and related social justice
              obligations in COVID-19 policy responses and all other policy
              making processes.
            </p>
            <p>
              The roundtable further concluded that in some instances there was
              a gap between policy and implementation and as a result many
              groups and communities were falling through the cracks
              particularly with regard to fair and equal access to adequate
              health services and distribution of COVID-19 relief. This resulted
              in the formation of the Social Justice and COVID-19 Policy and
              Relief Monitoring Alliance (SCOPRA), which monitors ongoing
              unintended consequences of both policies and conduct of state
              functionaries and others across all Working Group domains.
            </p>
          </div>
        </CollapsibleBox>
        <CollapsibleBox collapsibleByDefault>
          <h2>Objectives</h2>
          <div>
            <p>
              Establishing a secretarial and ward based tentacles social
              accountability forum dedicated to constantly assessing and
              ensuring:
            </p>
            <ol>
              <li>
                That the government and businesses are aware of social impact
                conscious and social justice responsive policy making
              </li>
              <li>Law and purpose fidelity of implementation</li>
              <li>No one is left behind, at ward level and groups</li>
              <li>
                Weekly feedback to government by way of a Policy and Rule of Law
                Index and Socio-Economic Relief Delivery Dash Board
                disaggregated by municipalities and selected disadvantaged
                groups
              </li>
              <li>
                Empowering the citizens through information on what they are
                entitled to, where to go for help and who to hold accountable,
                linking them where necessary
              </li>
              <li>Involving the international community</li>
            </ol>
          </div>
        </CollapsibleBox>
        <CollapsibleBox collapsibleByDefault>
          <h2>Desired Outputs and Outcomes</h2>
          <div>
            <h3>Outputs</h3>
            <ol>
              <li>Roundtables and reports</li>
              <li>Thematic group dialogues and reports</li>
              <li>Digital Policy and Rule of Law Index</li>
              <li>Digital Socio-Economic Relief Coverage Dash Board</li>
              <li>
                Vibrant social accountability network on Social Justice and
                Coronavirus COVID-19
              </li>
            </ol>
            <h3>Outcomes</h3>
            <ol>
              <li>
                Social justice impact awareness among key policy makers in
                government
              </li>
              <li>An increase in socially just policies</li>
              <li>
                Fair distribution of all Coronavirus COVID-19 Socio-Economic
                relief across all municipal wards and groups
              </li>
              <li>
                Rationalisation of private socio-economic relief services across
                all municipal wards and underserviced groups
              </li>
              <li>
                Improved social accountability among ordinary persons thus
                exposing and reducing corruption, clientelism, capture
              </li>
              <li>
                Improved public trust and sustained legitimacy of COVID-19
                Policy measures
              </li>
              <li>
                Enhanced achievement of SDG objectives relating to hunger,
                poverty, inequality and peace
              </li>
            </ol>
          </div>
        </CollapsibleBox>
        <CollapsibleBox collapsibleByDefault>
          <h2>Key Beneficiaries</h2>
          <div>
            <ol>
              <li>
                All persons and communities in 4392 wards paying special
                attention to the plight of groups historically disadvantaged in
                terms of social class, race, gender, disability, age,
                nationality, geographic area.
              </li>
              <li>
                Project to be scaled to the rest of Africa in four (4) months’
                time.
              </li>
            </ol>
          </div>
        </CollapsibleBox>
      </Section>
      <Section>
        <h2>Read more about the Chair's work on COVID-19</h2>
        <Button to="/blog/topic/covid-19/" size="md">
          Read the latest articles &rarr;
        </Button>
      </Section>
      <Section isShaded>
        <h2>LexisNexis COVID-19 Resource Centre</h2>
        <LeadText>
          The Centre for Social Justice is partnering with LexisNexis.
        </LeadText>
        <p>
          COVID-19 represents a common enemy that we are all fighting across the
          globe. It continues to test all aspects of our societies - our people,
          our infrastructure, our systems and processes - and indeed, even our
          understanding and implementation of the Rule of Law.
        </p>
        <p>
          Legal technology company, LexisNexis South Africa, has drawn upon its
          global expertise to launch a COVID-19 Free Resource Centre aimed at
          informing the public of its rights and responsibilities during this
          period, as well as the rights and obligations of employers, employees
          and businesses.
        </p>
        <p>
          The COVID-19 portal provides free access to a convenient online
          platform housing resources and information that cover a variety of
          general public themes such as Labour Law, Immigration, Property and
          Planning, as well as Notices, Court Directives and Regulations
          pertaining to COVID-19.
        </p>
        <p>
          The site also includes commentary from legal experts around the
          country on emerging themes and topics around COVID-19 matters. In
          addition, LexisNexis’s editorial team has made available COVID- 19
          legislation and regulation changes on the same site at no cost to the
          public. This provides direct access to a consolidated and
          authoritative source of COVID-19 regulatory information, together with
          useful tips, data and other helpful resources.
        </p>
        <Button
          to="https://www.lexisnexis.co.za/news-and-insights/covid-19-resource-centre?utm_source=social-justice-hub&utm_medium=referral&utm_campaign=covid-19"
          size="md"
        >
          Visit the LexisNexis resource centre &rarr;
        </Button>
      </Section>
    </Layout>
  );
};

export default ProjectsSCOPRA;

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "home-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    SULogoDark: file(relativePath: { eq: "su-logo-dark.jpg" }) {
      childImageSharp {
        fixed(quality: 100, width: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
